<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Branch')" label-for="product-branch">
         <span style="color: #f3a152;font-weight: bold;font-size: larger">{{getBranchName(whitelabel.buid)}}</span>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4">

        <b-form-group :label="msg('Logo Information Page')" label-for="whitelabel-company">
          <b-form-input class="mb-1" id="realEstate-sell-price" readonly v-model="whitelabel.logo1"
          />
          <vue-dropzone id="contract-upload" style="background-color: rgba(252,252,252,0.51)" class="h-100"
                        :options="dropzoneOptions" :useCustomSlot=true
                        :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                        @vdropzone-success="uploadSuccess">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title" style="color: black!important;">Drag and drop  Logo 1</h3>
              <div class="subtitle" style="color: black!important;">...or click to select the logo from your
                computer
              </div>
            </div>
          </vue-dropzone>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4">

        <b-form-group :label="msg('Logo Contract Page')" label-for="whitelabel-company">
          <b-form-input class="mb-1" id="realEstate-sell-price" readonly v-model="whitelabel.logo2"
          />
          <vue-dropzone id="contrdfact-upload" style="background-color: rgba(252,252,252,0.51)" class="h-100"
                        :options="dropzoneOptions2" :useCustomSlot=true
                        :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                        @vdropzone-success="uploadSuccess">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title" style="color: black!important;">Drag and drop  Logo 2</h3>
              <div class="subtitle" style="color: black!important;">...or click to select the logo from your
                computer
              </div>
            </div>
          </vue-dropzone>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4">

        <b-form-group :label="msg('Logo Top Left Corner')" label-for="whitelabel-company">
          <b-form-input class="mb-1" id="realEstate-sell-price" readonly v-model="whitelabel.logo3"
          />
          <vue-dropzone id="contract-uplfoad" style="background-color: rgba(252,252,252,0.51)" class="h-100" on
                        :options="dropzoneOptions3" :useCustomSlot=true
                        :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                        @vdropzone-success="uploadSuccess">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title" style="color: black!important;">Drag and drop  Logo 3</h3>
              <div class="subtitle" style="color: black!important;">...or click to select the logo from your
                computer
              </div>
            </div>
          </vue-dropzone>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">

        <b-form-group :label="msg('Logo Mobile version')" label-for="whitelabel-company">
          <b-form-input class="mb-1" id="realEstate-sell-price" readonly v-model="whitelabel.logo4"
          />
          <vue-dropzone id="contract-upldfoad" style="background-color: rgba(252,252,252,0.51)" class="h-100" on
                        :options="dropzoneOptions4" :useCustomSlot=true
                        :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                        @vdropzone-success="uploadSuccess">
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title" style="color: black!important;">Drag and drop  Logo 4</h3>
              <div class="subtitle" style="color: black!important;">...or click to select the logo from your
                computer
              </div>
            </div>
          </vue-dropzone>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Color 1')" label-for="whitelabel-codfmpany">
          <b-form-input id="realfsEstate-sell-price" v-model="whitelabel.color1" @change="onValueUpdated"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Color 2')" label-for="whitelabel-compafdny">
          <b-form-input id="realEdfgstate-sell-price" v-model="whitelabel.color2" @change="onValueUpdated"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Color 3')" label-for="whitelabel-compdfany">
          <b-form-input id="realEsedtate-sell-price" v-model="whitelabel.color3" @change="onValueUpdated"
          />
        </b-form-group>
      </b-col>



    </b-row>  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import vue2Dropzone from "vue2-dropzone";

export default {
  name: 'whitelabelManagement',
  components:{vueDropzone: vue2Dropzone,},
  data:()=>({
    logoType: null,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('data', ['getOtherUsers', 'getUsersNotByRole', 'getUsersByRole', 'getUserFullName', 'getUserColor', 'getUserAcronyms', 'getAllLanguage', 'getAllCountries', 'getAllUsers', 'getAllBranches', 'isEnergyOrg', 'isCompanyClients']),
    ...mapGetters('data', ['getBranchName']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    dropzoneOptions() {

      return {
        url: window.config.apiUrl + 'whitelabel/upload/logo/' + this.whitelabel.id + '?tag=logo1',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*",
      }
    },
    dropzoneOptions2() {

      return {
        url: window.config.apiUrl + 'whitelabel/upload/logo/' + this.whitelabel.id + '?tag=logo2',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*",
      }
    },
    dropzoneOptions3() {

      return {
        url: window.config.apiUrl + 'whitelabel/upload/logo/' + this.whitelabel.id + '?tag=logo3',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*",
      }
    },dropzoneOptions4() {

      return {
        url: window.config.apiUrl + 'whitelabel/upload/logo/' + this.whitelabel.id + '?tag=logo4',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*",
      }
    },

    whitelabel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    uploadComplete(file, response) {
      let responseData = JSON.parse(file.xhr.responseText);
      let whitelabel = responseData.objects["Whitelabel"];
      this.whitelabel = whitelabel;
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['Whitelabel'] == null)
          return;
        this.whitelabel = response.data['Whitelabel'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    }
    ,
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
